import { Logo } from 'components/Logo'
import styles from './Footer.module.scss'

function Footer() {
  return (
    <footer>
      <Logo size="55px" />
      <address>
        <br />
        Phone: <a href="tel:+14804634043">480-463-4043</a>
        <br />
        <br />
        Sunridge Counseling
        <br />
        9139 W Thunderbird Road
        <br />
        Suite 220
        <br />
        Peoria, AZ 85381
        <br />
        United States
      </address>
      <div className={styles.disclaimer}>
        <p>
          Disclaimer: This website is for informational and educational purposes
          and does not constitute, establish, initiate, or imply any sort of
          professional-patient relationship. The information on this site is not
          a substitute for professional psychological, psychiatric, or medical
          advice, diagnosis, or treatment. I do not sell, rent, or disclose your
          personal information to lists or third parties, and I will not provide
          your personal information to any third-party individual, government
          agency, or company unless compelled to do so by law. If you are having
          an emergency, call 911 or visit your local emergency room. If you are
          in crisis, you can call or text &quot;988&quot;, the National Suicide
          and Crisis Lifeline, to speak to a crisis counselor.
        </p>
      </div>
    </footer>
  )
}

export { Footer }
