import Head from 'next/head'
import { Layout } from 'components/Layout'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import 'styles/globals.scss'

function App({ Component, pageProps }) {
  return (
    <>
      <GoogleAnalytics strategy="worker" />
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="./favicon.ico" />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  )
}

export default App
