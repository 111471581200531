import { Header } from 'components/Header'
import { Footer } from 'components/Footer'
import styles from './Layout.module.scss'

function Layout({ children }) {
  return (
    <div className={styles.container}>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  )
}

export { Layout }
