import { Logo } from 'components/Logo'
import { LinkButton } from 'components/LinkButton'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { MenuButton } from 'components/MenuButton'
import styles from './Header.module.scss'

function Header() {
  const { pathname: fullPathName = '/' } = useRouter()
  const [menuOpen, setMenuOpen] = useState(false)

  const pathname = fullPathName.split('?')[0]

  const mobileNav = (
    <div className={styles.mobileNav}>
      <ul>
        <li>
          <Link href="/">
            <a
              onClick={() => setMenuOpen(false)}
              className={`${'/' === pathname && styles.active}`}
            >
              Home
            </a>
          </Link>
        </li>
        <li>
          <Link href="/weddings">
            <a
              onClick={() => setMenuOpen(false)}
              className={`${'/weddings' === pathname && styles.active}`}
            >
              Weddings
            </a>
          </Link>
        </li>
        <li>
          <Link href="/#faq">
            <a
              onClick={() => setMenuOpen(false)}
              className={`${'/#faq' === pathname && styles.active}`}
            >
              FAQ
            </a>
          </Link>
        </li>
        <li>
          <Link href="/contact">
            <a
              onClick={() => setMenuOpen(false)}
              className={`${'/contact' === pathname && styles.active}`}
            >
              Contact
            </a>
          </Link>
        </li>
      </ul>
    </div>
  )

  const desktopNav = (
    <div className={styles.header}>
      <div className={styles.left}>
        <Link href="/">
          <a onClick={() => setMenuOpen(false)}>
            <Logo size="55px" />
          </a>
        </Link>
      </div>
      <div className={`${styles.right} ${styles.desktop}`}>
        <Link href="/">
          <a className={`${styles.item} ${'/' === pathname && styles.active}`}>
            Home
          </a>
        </Link>
        <Link href="/weddings">
          <a
            className={`${styles.item} ${
              '/weddings' === pathname && styles.active
            }`}
          >
            Weddings
          </a>
        </Link>
        <Link href="/#faq">
          <a
            className={`${styles.item} ${
              '/#faq' === pathname && styles.active
            }`}
          >
            FAQ
          </a>
        </Link>
        <Link href="/contact" passHref>
          <LinkButton>Contact</LinkButton>
        </Link>
      </div>
      <div className={`${styles.right} ${styles.mobile}`}>
        <MenuButton
          onClick={() => setMenuOpen(!menuOpen)}
          showClose={menuOpen}
        />
      </div>
    </div>
  )

  return (
    <nav>
      <div className={styles.fixedHeader}>
        {desktopNav}
        {menuOpen && mobileNav}
      </div>
    </nav>
  )
}

export { Header }
