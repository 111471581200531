import styles from './LinkButton.module.scss'
import React from 'react'

const LinkButton = React.forwardRef(
  ({ onClick, href, color, children }, ref) => {
    return (
      <a
        href={href}
        onClick={onClick}
        ref={ref}
        className={`${styles.button} ${color && styles[color]}`}
      >
        {children}
      </a>
    )
  }
)
LinkButton.displayName = 'LinkButton'

export { LinkButton }
