import { useId } from 'react'

function Logo({ size = '20vw' }) {
  if (typeof size !== 'string') {
    throw new Error('size must be a string')
  }
  if (!size.endsWith('px') && !size.endsWith('vw')) {
    throw new Error("size must end with 'px' or 'vw'")
  }
  const id = useId()
  return (
    <>
      <div className="outer-container">
        <div className="container">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            id={`logo-img-${id}`}
            className="logo"
            src="/logo.svg"
            alt="Sunridge Counseling Logo"
          />
          <span id={`logo-text-${id}`} className="logo-text">
            SUNRIDGE
            <br />
            COUNSELING
          </span>
        </div>
      </div>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx>{`
        .logo-text {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          letter-spacing: -1px;
          line-height: 0.9;
          user-select: none;
          text-transform: uppercase;
          font-size: calc(${size} / 3);
          padding-left: calc(${size} / 9);
        }
        .container {
          display: flex;
          align-items: center;
        }
        .outer-container {
          /* margin: calc(${size} / 18); */
        }
        .logo {
          height: ${size};
          width: ${size};
          user-select: none;
        }
      `}</style>
    </>
  )
}

export { Logo }
